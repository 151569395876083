import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { useInView } from 'react-intersection-observer'
import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

export interface DoubleCTAProps {
  data: {
    text: any[]
    ctas: any[]
    subtext?: string
  }
}

export const DoubleCTA = ({ data }: DoubleCTAProps) => {
  const {
    text,
    ctas,
    subtext
  } = data

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true
  })

  return (
    <div className='x mt4 mb5 p1 double__cta mt6--800' ref={ref}>
      <div className='container--1300 p1 p2--800 mxa'>
        <div className='ba bc'>
          <div className='row gutter--none df fw'>
            <div className='col double__cta-image c7 bc br--1000 c4--1000'>
              <div className='p2--600'>
                <Image className='x' imageId={ctas[0].image.imageId} src={ctas[0].image.url}  />
              </div>
            </div>
            <div className='col c14 c6--1000 bb bbn--1000 bc tc'>
              <div className='df y jcc aic jcb fdc'>
                <div className='y double__cta-text mt2 df jcc aic'>
                  <div className={cx('p2 p4--800 s60--1000 animate-text', inView && 'animated')}>
                    <BlockContent blocks={text} serializers={Serializer} />
                  </div>
                </div>
                <div className='row x df jcb fw double__cta-buttons show--1000 gutter--none'>
                  <div className='col14 col'>
                    <div className='row x bb bt bc gutter--none x'>
                      <div className='col double__cta-col' />
                      <div className='col double__cta-button bn c6'>
                        <Link to={`/collections/${ctas[0].linked.slug.current}`} className='button bn db x'>
                          <span>{ctas[0].ctaText}</span>
                        </Link>
                      </div>
                      <div className='col double__cta-col' />
                      <div className='col double__cta-button bn c6'>
                        <Link to={`/collections/${ctas[1].linked.slug.current}`} className='button bn db x'>
                          <span>{ctas[1].ctaText}</span>
                        </Link>
                      </div>
                      <div className='col double__cta-col' />
                    </div>
                  </div>
                  <div className='col c14'>
                    <div className='p2'>
                      <span className='caps founders s18'>{subtext}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col double__cta-image c7 bc bl--1000 c4--1000'>
              <div className='p2--600'>
                <Image className='x' imageId={ctas[1].image.imageId} src={ctas[1].image.url}    />
              </div>
            </div>
            <div className='row x df fw double__cta-buttons double__cta-mobile  hide--1000 gutter--none'>

              <div className='col double__cta-button c7'>
                <Link to={`/collections/${ctas[0].linked.slug.current}`} className='button df jcc aic y btn bbn bln br1 bc x'>
                  <span>{ctas[0].ctaText}</span>
                </Link>
              </div>

              <div className='col double__cta-button bl c7'>
                <Link to={`/collections/${ctas[1].linked.slug.current}`}  className='button  y df jcc aic bn bc x'>
                  <span>{ctas[1].ctaText}</span>
                </Link>
              </div>

              <div className='col bt c14'>
                <div className='p2 tc x'>
                  <span className='caps founders s18'>{subtext}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoubleCTA